export const useMobile = () => {
  const isMobile = ref(false);

  const checkMobile = () => {
    if (window.innerWidth < 768) {
      isMobile.value = true;
    } else {
      isMobile.value = false;
    }
  };

  onMounted(() => {
    checkMobile();
    window.addEventListener('resize', checkMobile);
  });

  onUnmounted(() => {
    window.removeEventListener('resize', checkMobile);
  });

  return { isMobile };
};
