<script setup lang="ts">
import { useMobile } from '#build/imports';
import type { HorizontalNavigationLink } from '#ui/types';

const userStore = useUserStore();

const colorMode = useColorMode();

const isDark = computed({
  get() {
    return colorMode.value === 'dark';
  },
  set() {
    colorMode.preference = colorMode.value === 'dark' ? 'light' : 'dark';
  },
});

const debugItem = computed<HorizontalNavigationLink | undefined>(() => {
  return undefined;

  // if (userStore.user?.group) {
  //   return {
  //     label: '调试',
  //     labelClass: 'text-red-400 opacity-70',
  //     iconClass: '!text-red-400 opacity-60', // overwrite icon color
  //     to: '/dashboard/debug',
  //     icon: 'mingcute:settings-3-line',
  //   };
  // } else {
  //   return undefined;
  // }
});

const colorThemeItem = computed<HorizontalNavigationLink>(() => {
  const icon = !isDark.value ? 'carbon:moon' : 'carbon:sunny';
  const label = isDark.value ? '开灯' : '关灯';
  return {
    label: isMobile.value ? '' : label,
    icon,
    click: () => {
      toggleTheme();
    },
  };
});

const defaultItemStyleClass = {
  labelClass: 'text-neutral-900 dark:text-neutral-100',
  iconClass: '!text-neutral-900 !w-4 !h-4 dark:!text-neutral-100',
};

const { isMobile } = useMobile();
const links = computed<HorizontalNavigationLink[][]>(() => {
  return [
    [
      {
        label: '我的',
        to: '/dashboard',
        icon: isMobile.value ? '' : 'mingcute:home-3-line',
        ...defaultItemStyleClass,
      },
      {
        label: '订阅',
        to: '/dashboard/subscription',
        icon: isMobile.value ? '' : 'mingcute:wechat-miniprogram-line',
        ...defaultItemStyleClass,
      },
      {
        label: '谷子',
        to: '/dashboard/goods',
        icon: isMobile.value ? '' : 'mingcute:calendar-month-line',
        ...defaultItemStyleClass,
      },
      debugItem.value,
    ].filter(Boolean) as HorizontalNavigationLink[],
    [colorThemeItem.value].filter(Boolean) as HorizontalNavigationLink[],
  ];
});

function toggleTheme() {
  isDark.value = !isDark.value;
}
</script>

<template>
  <div
    class="border-b w-full mx-0 p-0 py-1 px-4 border-gray-400/10 dark:border-neutral-600/15"
  >
    <div class="max-w-[1200px] flex items-center mx-auto px-0">
      <div
        class="text-nowrap mr-2 select-none text-lg bg-gradient-to-r text-transparent bg-clip-text from-orange-600 to-black dark:from-orange-400 dark:to-white/85"
      >
        {{ isMobile ? '🐈' : '🐈次元喵屋' }}
      </div>
      <UHorizontalNavigation :links="links" />
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
